code {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji";
}

// Mantine overrides

// Other styles

// Giving mantine table first column some padding

.datatable tr th:first-child .mantine-TableHeadCell-Content,
.datatable tr th:first-child .mantine-InputWrapper-root {
  margin-left: 25px;
}

.datatable tbody tr td:first-child {
  padding-left: 40px;
}

.datatable {
  right: 10px;
}

.datatable .datatable-row.active {
  background-color: saddlebrown;
}

// Normal table

.normal-table th {
  font-weight: 700 !important;
}

// hover table

.hover-table tr:hover {
  cursor: pointer;
}

// form inputs
.mantine-InputWrapper-error,
.mantine-InputWrapper-description {
  font-size: 13px;
}

.mantine-Breadcrumbs-root {
  overflow: hidden;
}
/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}

.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
  font-family: "Courier New";
}

.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}

.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}

.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji";
}

.loading {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: white;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 60px;
  display: flex;
  justify-content: space-evenly;
}

.ball {
  list-style: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #2758c950;
}

.ball:nth-child(1) {
  animation: bounce-1 0.8s ease-in-out infinite;
}

@keyframes bounce-1 {
  50% {
    transform: translateY(-18px);
    background-color: #2758c9;
  }
}

.ball:nth-child(2) {
  animation: bounce-3 0.8s ease-in-out 0.3s infinite;
}

@keyframes bounce-2 {
  50% {
    transform: translateY(-18px);
    background-color: #c98b27;
  }
}

.ball:nth-child(3) {
  animation: bounce-3 0.8s ease-in-out 0.6s infinite;
}

@keyframes bounce-3 {
  50% {
    transform: translateY(-18px);
    background-color: #c98b27;
  }
}
